import React from 'react'
import { Link } from 'react-router-dom';
function Nav() {
    return (
        <div>
            <nav className="nav-main mainmenu-nav d-none d-xl-block">
                <ul className="mainmenu">

                <li> <Link className="nav-item" to={'/'}> Home </Link> </li>
        <li className="has-droupdown"> <Link className="nav-link" to={'/about-us'}> About us </Link>
                        <ul className="submenu">
                            <li>
                                <Link to={'/our-vision'}> Our Vision </Link>
                            </li>
                            <li>
                                <Link to={'/our-mission'}> Our Mission </Link>
                            </li>
                        </ul>
                    </li>



                    <li className="has-droupdown">
                        <Link className="nav-link" to={'#'}>
                    Products
                        </Link>
                        <ul className="submenu">
                            <li>
                                <Link to={'/rugged-servers'}> Rugged Servers </Link>
                            </li>
                            <li>
                                <Link to={'/aerospace'}> Rugged Displays </Link>
                            </li>
                            <li>
                                <Link to={'/rugged-consoles'}> Rugged KVM Displays </Link>
                            </li>
                            <li>
                                <Link to={'/railways'}>  Rugged Panel PCs </Link>
                            </li>
                            <li>
                                <Link to={'/railways'}> Rugged Workstations </Link>
                            </li>
                            <li>
                                <Link to={'/railways'}> Rugged Servers </Link>
                            </li>

                            <li>
                                <Link to={'/railways'}> Rugged Ethernet Switches </Link>
                            </li>
                        </ul>
                    </li>
                  
                    <li className="has-droupdown">
                        <Link className="nav-link" to={'#'}>
                    Industries
                        </Link>
                        <ul className="submenu">
                            <li>
                                <Link to={'/military-defence'}> Military & Defence </Link>
                            </li>
                            <li>
                                <Link to={'/aerospace'}> Aerospace </Link>
                            </li>
                            <li>
                                <Link to={'/government-psu'}> Government & PSU </Link>
                            </li>
                            <li>
                                <Link to={'/railways'}>  Railways </Link>
                            </li>
                            <li>
                                <Link to={'/healthcare'}> Healthcare </Link>
                            </li>

                        </ul>
                    </li>
                 
                    <li>
                        <Link className="nav-item" to={'/contactus'}>
                            Contact us
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav