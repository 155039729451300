import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import BannerOne from "../components/banner/BannerOne";
import BrandOne from "../components/brand/BrandOne";
import TeamOne from "../components/team/TeamOne";
import ContactForm from "../components/contactform/ContactForm";
import MapOne from "../components/map/MapOne";
import { Link } from 'react-router-dom';



import FooterOne from "../components/footer/FooterOne";

function HomeOne() {
  return (
    <div>
      <HeaderOne />

      <BannerOne />

      <section className='aboutsec'>
    <div><h2> About us </h2>
    <p> Planetronix is a homegrown manufacturer of bespoke, rugged systems, and they also serve as a partner for numerous international Original Equipment Manufacturers (OEMs) for sales, support, and spare parts management in India. </p> 
    <Link to="/about-us">   <button className='button'> View more </button> </Link>
    </div>

    <div><h2> Vision </h2>
    <p> To offer effective services without relinquishing quality and to fulfil the needs of the client by developing their business, holistically. We intend to help the business accomplish a remarkable exhibition online through our imaginative approach.  </p> 
    <Link to="/our-vision">   <button className='button'> View more </button> </Link>
    </div>

    <div><h2> Mission </h2>
    <p> To give our clients the most compelling experience possible and build a long term relationship by becoming one of the top IT solution providers worldwide. </p>
    <Link to="/our-mission">   <button className='button'> View more </button> </Link>
     </div>
</section>

      <BrandOne />
      <TeamOne />
      <ContactForm />
      <MapOne />
      <FooterOne />

    </div>
  )
}

export default HomeOne