import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import FooterOne from "../components/footer/FooterOne";
import Accordion from 'react-bootstrap/Accordion';

const RuggedServers = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Rugged Servers' }
    ];
  return (
    <>  
     
    <div className=''>
<HeaderOne />
<Breadcrumb title="Rugged Servers" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <h4 className="title"> Rugged Servers </h4>
                    <p className="disc"> Amity Rugged Xeon Scalable Series Servers are designed to exceed your expectations in any environment. Tested across military, industrial, and commercial programs on sea, land and in air. Certified to comprehensive Military and Industrial Standards (MIL-STD-810, CE, FCC, etc.) for added compatibility, hardened security, and undeniable reliability.  </p>
                </div>  
<div className='row'> 
    <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <h5 className='title'> Intel Xeon-E Series or Xeon Scalable CPU  </h5>
        <p> Extended performance using Xeon E3-E5 Series or Xeon Scalable Processor up to 3.8GHz Clock rate, 28 Cores (56 Threads), 38.5M Cache, and up to 205W TDP. </p>
         </div>

    <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
        <img src='../../assets/images/products/processor.jpg' alt='processor'  />
          </div>



          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 

<img src='../../assets/images/products/processor.jpg' alt='processor'  />

</div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> Expandable Memory     </h5>
          <p className="disc"> Maximize your memory with twelve carefully optimized ECC DIMMs across 6 channels and up to 1.5 TB DDR42666 memory. The best server class RAM in Servers. </p>

          </div>

         

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> SATA Storage  </h5>
          <p className="disc"> Extend your Storage up to 14 SATA 6Gb/s front access SSD drive bays with hot swap drives. You can expand your storage even further with addition SSD/HDD SATA drive slots. </p>

          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/storage.jpg' alt='processor'  />
          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/add-on-cards.jpg' alt='processor'  />
          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 

          <h5 className='title'> Add-On-Cards PCIe Slots  </h5>
          <p className="disc"> Up to 7 PCIe Gen 3 Slots with high bandwidth and even more PCIe slots can be installed to our rugged server with PCIe Expansion kit. </p>

          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> Power  </h5>
          <p className="disc"> Up to 1500W (AC) power supply or 800W (DC) and even two power supply units into one 3U / 4U / 5U Servers and can run the psu for most demanding applications.

</p>
 </div>

 <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/power.jpg' alt='power'  />
          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/iports.jpg' alt='processor'  />
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> I/O Options  </h5>
         <ul>
<li> 1 / 10Gbe LAN MIL-STD Circular Port  </li>
<li> USB 2.0 or 3.0 MIL-STD Circular / Panel
</li>
<li> SATA/600 Ports with Raid Add-On-Card through SSD drive bay
</li>
<li> HDMI / DVI Panel Mount Video Ports
</li>
<li>  RS 232 /422/485 Serial port D-Sub ports
</li>

         </ul>
 </div>
     </div>


            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Rugged Computers </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/military-defence'}>
                                    Military & Defence <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/aerospace'}>
                                    Aerospace <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/government-psu'}>
                                    Government & PSU <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/railways'}>
                                    Railways <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/healthcare'}>
                                    Healthcare <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                    </div>
                </div> 
                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}




{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                       
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span>  Industry Expertise </Accordion.Header>
                                <Accordion.Body> Decades of experience in the aviation sector. </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span>  Comprehensive Solutions </Accordion.Header>
                                <Accordion.Body> From procurement to maintenance and logistics, we offer a one-stop solution.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span>03. </span> Global Network </Accordion.Header>
                                <Accordion.Body>
                                Access to certified suppliers and partners worldwide.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span>04. </span> Advanced Technology </Accordion.Header>
                                <Accordion.Body>
                                Leverage real-time data, automation, and predictive analytics.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span>05. </span> Regulatory Compliance </Accordion.Header>
                                <Accordion.Body>
                                Ensure you meet all local and international standards.
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}

<FooterOne />
</div>


    </>
  )
}

export default RuggedServers